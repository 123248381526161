<template>
  <div class="user-interface profile">
    <div class="sidebar noselect col-mobile-displaynone">
      <div class="logo">
        <img src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png" alt="Logo" />
        <h3>{{ $t('profile.title') }}</h3>
      </div>
      <div class="list">
        <ul>
          <li>
            <router-link :to="{ name: 'profile-general' }">
              <div class="col-pc-3">
                <i class="fas fa-user"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.general') }}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile-security' }">
              <div class="col-pc-3">
                <i class="fas fa-lock"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.security') }}</span>
              </div>
            </router-link>
          </li>
          <!--
          <li>
            <router-link :to="{ name: 'profile-payment-methods' }">
              <div class="col-pc-3">
                <i class="fas fa-credit-card"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.payment-methods') }}</span>
              </div>
            </router-link>
          </li>
          -->
          <li>
            <router-link :to="{ name: 'profile-communities' }">
              <div class="col-pc-3">
                <i class="fas fa-users"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.communities') }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="return">
        <ul>
          <li>
            <router-link :to="{ name: 'communities' }">
              <div class="col-pc-3">
                <i class="fas fa-long-arrow-alt-left"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.return') }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-content col-pc-11 col-mobile-12">
      <div
        class="mobile-topbar noselect col-pc-displaynone-important col-mobile-12 col-f"
        :class="{ active: isMobileMenuOpen === true }"
      >
        <div class="left col-mobile-3">
          <a
            href="#"
            class="menu-icon"
            :class="{ active: isMobileMenuOpen }"
            @click="
              isMobileMenuOpen = !isMobileMenuOpen
              isMobileUserDropdownOpen = false
            "
          >
            <i class="fas fa-bars"></i>
          </a>
        </div>
        <div class="center col-mobile-6">
          <div class="logo">
            <img
              src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
              alt="Logo"
              width="70px"
              height="70px"
            />
          </div>
        </div>
        <div class="right col-mobile-3">
          <a
            href="#"
            class="image"
            :class="{ active: isMobileUserDropdownOpen }"
            @click="
              isMobileUserDropdownOpen = !isMobileUserDropdownOpen
              isMobileMenuOpen = false
            "
          >
            <VueGravatar v-if="!user.image" :size="120" :email="user.email" default-img="retro" />
            <img v-if="user.image" :src="user.image" alt="user image" />
          </a>
        </div>
      </div>
      <div
        class="mobile-dropdowns-container col-mobile-12 col-pc-displaynone"
        v-if="isMobileMenuOpen || isMobileUserDropdownOpen"
      >
        <div class="menu-dropdown col-mobile-12" :class="{ active: isMobileMenuOpen === true }">
          <div class="list">
            <ul>
              <li>
                <router-link :to="{ name: 'profile-general' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-user"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.sidebar.general') }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'profile-security' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-lock"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.sidebar.security') }}</span>
                  </div>
                </router-link>
              </li>
              <!--
          <li>
            <router-link :to="{ name: 'profile-payment-methods' }">
              <div class="col-pc-3">
                <i class="fas fa-credit-card"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.payment-methods') }}</span>
              </div>
            </router-link>
          </li>
              -->
              <li>
                <router-link :to="{ name: 'profile-communities' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.sidebar.communities') }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
            <ul class="return-mobile">
              <li>
                <router-link :to="{ name: 'communities' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.sidebar.return') }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
            <div class="separator"></div>
            <ul class="language-selector">
              <div class="subtitle">
                <h2>{{ $t('profile.topbar.settings') }}</h2>
              </div>
              <div class="col-all-3">
                <LanguageSelector class="side-right"></LanguageSelector>
              </div>
            </ul>
          </div>
        </div>

        <div
          class="user-dropdown col-mobile-12"
          :class="{ active: isMobileUserDropdownOpen === true }"
        >
          <div class="list">
            <ul>
              <li>
                <router-link :to="{ name: 'profile-security' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-lock"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.topbar.change-password') }}</span>
                  </div>
                </router-link>
              </li>
              <li class="logout-mobile">
                <a @click="logout">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-sign-out-alt"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.topbar.logout') }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="topbar col-mobile-displaynone-important col-pc-12">
        <div class="left col-pc-6">
          <LanguageSelector class="side-right"></LanguageSelector>
        </div>
        <div class="right col-pc-6">
          <div
            class="user"
            :class="{ active: isUserDropdownActive }"
            @click="isUserDropdownActive = !isUserDropdownActive"
            v-click-outside="
              () => {
                isUserDropdownActive = false
              }
            "
          >
            <span>{{ user.email }}</span>
            <div class="image">
              <VueGravatar v-if="!user.image" :size="120" :email="user.email" default-img="retro" />
              <img v-if="user.image" :src="user.image" alt="user image" />
            </div>
            <transition name="menu-popover">
              <div class="user-dropdown" v-if="isUserDropdownActive">
                <!-- :class="{ active: isUserDropdownActive }"> -->
                <ul>
                  <router-link :to="{ name: 'profile-security' }">
                    <li>
                      <i class="fas fa-lock"></i>
                      <span>{{ $t('profile.topbar.change-password') }}</span>
                    </li>
                  </router-link>
                  <a @click="logout">
                    <li>
                      <i class="fas fa-sign-out-alt"></i>
                      <span>{{ $t('profile.topbar.logout') }}</span>
                    </li>
                  </a>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="content col-pc-12 col-mobile-11">
        <router-view></router-view>
      </div>

      <v-tour
        :name="this.$route.name"
        :steps="tourSteps"
        :callbacks="finishCallbackOnly"
        :options="translatedLabels"
      ></v-tour>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>
<script>
import LanguageSelector from '@/components/LanguageSelector'
import TourManager from '@/mixins/TourManager'
import ClickOutside from 'vue-click-outside'
import VueGravatar from 'vue-gravatar'

export default {
  name: 'profile',
  mixins: [TourManager],
  components: {
    LanguageSelector,
    VueGravatar
  },
  data() {
    return {
      isUserDropdownActive: false,
      isMobileMenuOpen: false,
      isMobileUserDropdownOpen: false,
      tourSteps: [
        {
          target: '.v-step-general-section',
          content: this.$t('tour.profile.general-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'profile-general' })
              resolve()
            })
        },
        {
          target: '.v-step-security-section',
          content: this.$t('tour.profile.security-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'profile-security' })
              resolve()
            })
        },
        {
          target: '.v-step-communities-section',
          content: this.$t('tour.profile.communities-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'profile-communities' })
              resolve()
            })
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user || {}
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(response => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  watch: {
    $route(to, from) {
      this.isMobileMenuOpen = false
      this.isMobileUserDropdownOpen = false
      this.isUserDropdownActive = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
